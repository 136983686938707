input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}


.App {
  text-align: center;
  height: 100vh;
  width: 100vw;
}

.Image {
  position: absolute;
  top: 0;
  bottom: 0;
  left:0;
  right:0;
  margin:auto
}

.TitleDiv {
  width: 100%;
  border: 1px solid #132C58;
  /* background-color: #132C58; */
  background-color: white;
  height: 60px
}

.LoginDiv {
  height: 350px;
  width: 400px;
  align-self: center;
  margin : 12vh auto;
  border: 2px solid #CBCBCB;
  border-radius: 5px;
  display: "flex";
  flex-direction: row;
  padding:8vh 0 5vh 0;
  justify-content: flex-end;
  align-items: center;
}

.FormDiv {
  width: 800px;
  min-height: 50vh;
  margin: 1vh auto;
  z-index: 1;
  padding: 0.1vh 0.5vw 3vh 0.5vw;
  background-color: #132C58;
  /* border: 1px solid; */
}

@media screen and (max-width: 800px){
  .FormDiv {
    width: 100vw;
  }
}

.CardTitle {
  padding-top: 1vh;
  margin: 0 1vw;
  margin-top: 2vh;
}

.CardField {
  margin: 0.5vh 1vw;
  padding: 1rem 0;
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FieldDiv {
  margin-top: 1vh;
  margin-bottom: 1vh;
}